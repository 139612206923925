import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This`}<a parentName="p" {...{
        "href": "https://sb2019.dashbot.io"
      }}>{` SuperBot`}</a>{`, we had the opportunity to gather some of the leading innovators in the conversational AI space to pick their brains. We ran the gamut from customer service to the future of voice, and we wanted to make sure everyone could have access to all the information shared at SuperBot. We have you covered, whether you were at the conference and missed a panel or you couldn’t make it to San Francisco. Watch them all here.`}</p>
    <h2>{`The Future of Voice Panel`}</h2>
    <p>{`We brought in an amazing panel to discuss the ways voice tech is evolving and what we can expect in the future.`}</p>
    <p><strong parentName="p">{`Speakers`}</strong></p>
    <p>{`David Browning (`}<a parentName="p" {...{
        "href": "https://www.nba.com/#/"
      }}>{`NBA Digital`}</a>{`)`}<br parentName="p"></br>{`
`}{`Jonathon Myers (`}<a parentName="p" {...{
        "href": "https://www.earplay.com/"
      }}>{`Earplay`}</a>{`)`}<br parentName="p"></br>{`
`}{`Sandy Diep (`}<a parentName="p" {...{
        "href": "https://assistant.google.com/"
      }}>{`Google`}</a>{`)`}<br parentName="p"></br>{`
`}{`Mark Mezrich (`}<a parentName="p" {...{
        "href": "https://www.viacom.com/"
      }}>{`Viacom`}</a>{`)`}<br parentName="p"></br>{`
`}{`Morderated by: Mari Lescaille (`}<a parentName="p" {...{
        "href": "https://www.voicefirstlabs.com/"
      }}>{`VoiceFirst Labs`}</a>{`)`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/w32LFR_3P3Q?feature=oembed" width="1200"></iframe>
    <h2>{`User Acquisition and Discovery Panel`}</h2>
    <p>{`One of the biggest challenges a voice skill or a chatbot faces is finding those new users and getting them to return. Hear what industry experts are doing to grow their user base.`}</p>
    <p><strong parentName="p">{`Speakers`}</strong></p>
    <p>{`Kirk Owen (`}<a parentName="p" {...{
        "href": "https://xappmedia.com/"
      }}>{`XAPPmedia`}</a>{`)`}<br parentName="p"></br>{`
`}{`Christian Brucculeri (`}<a parentName="p" {...{
        "href": "https://snaps.io/"
      }}>{`Snaps`}</a>{`)`}<br parentName="p"></br>{`
`}{`Stephen Keefer (`}<a parentName="p" {...{
        "href": "https://www.accuweather.com/en/us/united-states-weather"
      }}>{`AccuWeather`}</a>{`)`}<br parentName="p"></br>{`
`}{`Nick Schwab (`}<a parentName="p" {...{
        "href": "https://invokedapps.com/"
      }}>{`Invoked Apps`}</a>{`)`}<br parentName="p"></br>{`
`}{`Morderated by: Joti Balani (`}<a parentName="p" {...{
        "href": "https://freshriver.ai/"
      }}>{`Freshriver AI`}</a>{`)`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/GkrY0UotAC4?feature=oembed" width="1200"></iframe>
    <h2>{`Monetization Strategies Panel`}</h2>
    <p>{`Ultimately, successful chatbot or voice skill deployment will come down to ROI. Our monetization panel shares its secrets to making sure you can bring in revenue.`}</p>
    <p><strong parentName="p">{`Speakers`}</strong></p>
    <p>{`Arjun Ohri (`}<a parentName="p" {...{
        "href": "https://www.shopmessage.me/"
      }}>{`ShopMessage`}</a>{`)`}<br parentName="p"></br>{`
`}{`Fang Cheng (`}<a parentName="p" {...{
        "href": "https://www.letslinc.com/"
      }}>{`Linc Global`}</a>{`)`}<br parentName="p"></br>{`
`}{`Sergio Passos (`}<a parentName="p" {...{
        "href": "https://take.net/"
      }}>{`Take`}</a>{`)`}<br parentName="p"></br>{`
`}{`Ben Parr (`}<a parentName="p" {...{
        "href": "https://octaneai.com/"
      }}>{`Octane AI`}</a>{`)`}<br parentName="p"></br>{`
`}{`Morderated by: Henry Hu (`}<a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`)`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Do1M6KbP3Y8?feature=oembed" width="1200"></iframe>
    <h2>{`Brands Leveraging Conversations Panel`}</h2>
    <p>{`A larger brand might face more difficulties when it comes to deploying a successful chatbot or voice skill, so we asked our panel what their strategies and best use cases are.`}</p>
    <p><strong parentName="p">{`Speakers`}</strong></p>
    <p>{`Brian Waite (`}<a parentName="p" {...{
        "href": "https://corporate.discovery.com/"
      }}>{`Discovery`}</a>{`)`}<br parentName="p"></br>{`
`}{`Spencer Uttley (`}<a parentName="p" {...{
        "href": "https://quickbooks.intuit.com/"
      }}>{`QuickBooks`}</a>{`)`}<br parentName="p"></br>{`
`}{`Mihai Antonescu (`}<a parentName="p" {...{
        "href": "https://www.daimler.com/company/north-america/mercedes-benz-r-d.html"
      }}>{`Mercedes Benz Research and Development`}</a>{`)`}<br parentName="p"></br>{`
`}{`Bree Glaeser (`}<a parentName="p" {...{
        "href": "http://www.themarsagency.com/news-events/"
      }}>{`The Mars Agency`}</a>{`)`}<br parentName="p"></br>{`
`}{`Morderated by: Karen Hao (`}<a parentName="p" {...{
        "href": "https://www.technologyreview.com/"
      }}>{`MIT Technology Review`}</a>{`)`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/UgMlrDtUN8o?feature=oembed" width="1200"></iframe>
    <h2>{`Scaling Chatbots for Customer Service Panel`}</h2>
    <p>{`A really fascinating use case for chatbots that’s been on the rise is customer service. What company wouldn’t love to offer 24/7 support? This panel dives into best practices and how to best utilize conversation for customer care.`}</p>
    <p><strong parentName="p">{`Speakers`}</strong></p>
    <p>{`Nikos Ioannou (`}<a parentName="p" {...{
        "href": "https://turbotax.intuit.com/"
      }}>{`TurboTax`}</a>{`)`}<br parentName="p"></br>{`
`}{`Paul Lasserre (`}<a parentName="p" {...{
        "href": "https://appfoundry.genesys.com/"
      }}>{`Genesys`}</a>{`)`}<br parentName="p"></br>{`
`}{`Mehmet Orgut (`}<a parentName="p" {...{
        "href": "https://turo.com/"
      }}>{`Turo`}</a>{`)`}<br parentName="p"></br>{`
`}{`Morderated by: Van Baker (`}<a parentName="p" {...{
        "href": "https://www.gartner.com/en"
      }}>{`Gartner`}</a>{`)`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/x9-sX6qDUHg?feature=oembed" width="1200"></iframe>
    <h2>{`Investor Insights Panel`}</h2>
    <p>{`Getting investors can be vital for a startup. We asked our panel of investors what they typically look for when investing in the conversational space.`}</p>
    <p><strong parentName="p">{`Speakers`}</strong></p>
    <p>{`Zain Gulamali (`}<a parentName="p" {...{
        "href": "https://developer.amazon.com/alexa-fund"
      }}>{`Amazon Alexa Fund`}</a>{`)`}<br parentName="p"></br>{`
`}{`Jenny Gao (`}<a parentName="p" {...{
        "href": "https://www.bvp.com/"
      }}>{`Bessemer Venture Partners`}</a>{`)`}<br parentName="p"></br>{`
`}{`Austin Arensberg (`}<a parentName="p" {...{
        "href": "https://scrum.vc/"
      }}>{`Scrum Ventures`}</a>{`)`}<br parentName="p"></br>{`
`}{`John Frankel (`}<a parentName="p" {...{
        "href": "https://ffvc.com"
      }}>{`ff Venture Capital`}</a>{`)`}<br parentName="p"></br>{`
`}{`Morderated by: Amanda Galton (`}<a parentName="p" {...{
        "href": "https://www.orrick.com/"
      }}>{`Orrick`}</a>{`)`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Qont49zCYLo?feature=oembed" width="1200"></iframe>
    <p>{`Thank you to everyone who participated in SuperBot and made it a huge success. We love bringing together people to share and learn from each other. If you’d like to hear about more Dashbot events, sign up for our mailing list here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      